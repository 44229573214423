<template>
    <div>
        <h1>Home Page</h1>
        <a href="/register">register</a>
        <br>
        <a href="/api/prequalify/Bentley?Testing=1">widget</a>
    </div>
</template>
<script>
export default {
    
}
</script>