<template>
  <div>
    <h1>Register</h1>

    <input type="email" v-model="email" placeholder="email" name="email" />
    <input type="password" v-model="password" placeholder="password" name="password"/>
    <input type="address" v-model="address" placeholder="address" name="address" />
    <button @click="register">Register</button>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";

export default {
  name: "elite-register",
  data() {
    return {
      email: "",
      password: "",
      address: ""
    };
  },
  methods: {
    async register() {
      const response = await AuthenticationService.register({
        email: this.email,
        password: this.password,
        address: this.address
      });
      console.log(response.data);
      // console.log('Time To Register', this.email, this.password);
    },
  },
};
</script>
