import { createRouter, createWebHistory } from "vue-router";

import Home from "../components/pages/Home.vue";
import ModalLanding from "../components/pages/ModalLanding.vue";
import Register from "../components/pages/Register.vue";
import Demo from "../components/pages/Demo.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/demo",
    name: "Demo",
    component: Demo,
  },
  {
    path: "/prequalify",
    name: "Prequalify",
    component: ModalLanding,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
