<template>
  <div class="prequal-demo">
    <div class="prequal-demo__wrapper">
      <div class="prequal-demo__container">
        <h4>Customer Only</h4>
        <button @click="launchCustomerModal">Get Pre-Qualified</button>
      </div>
      <div class="prequal-demo__container">
        <h4>Builder</h4>
        <button @click="launchBuilderModal">Get Pre-Qualified</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    launchCustomerModal() {
      const bodyStyles = window.getComputedStyle(document.body);
      const { maxHeight, overflow } = bodyStyles;
      document.body.style.maxHeight = "100vh";
      document.body.style.overflow = "hidden";
      let e = document.createElement("iframe");
      (e.src = "https://eliteleadsolutions.com/api/prequalify/Bentley"),
        (e.id = "elite-iframe"),
        (e.referrerPolicy = "origin"),
        (e.frameBorder = 0),
        (e.seamless = "true"),
        document.body.append(e),
        document
          .getElementById("elite-iframe")
          .addEventListener("load", function () {
            let e = document.getElementById("elite-iframe");
            (e.style.position = "absolute"),
              (e.style.top = window.scrollY.toString() + "px"),
              (e.style.left = "0"),
              (e.style.width = "100%"),
              (e.style.height = "100vh");
            let t = new MessageChannel();
            (t.port1.onmessage = () => {
              document.body.style.maxHeight = maxHeight;
              document.body.style.overflow = overflow;
              document.getElementById("elite-iframe").remove();
            }),
              e.contentWindow.postMessage("init", "*", [t.port2]),
              t.port1.postMessage(
                JSON.stringify({
                  companyName: "Bentley",
                  primaryColor: "#0c71c3",
                  secondaryColor: "#FFFFFF",
                  logo: "https://imagedelivery.net/oeoLvNGjolwd3S4Vf5dZZg/41785651-dab9-4e27-46d3-180c80828f00/hd",
                  demo: true,
                })
              );
          });
    },
    launchBuilderModal() {
      const bodyStyles = window.getComputedStyle(document.body);
      const { maxHeight, overflow } = bodyStyles;
      document.body.style.maxHeight = "100vh";
      document.body.style.overflow = "hidden";
      let e = document.createElement("iframe");
      (e.src = "https://eliteleadsolutions.com/api/prequalify/Bentley"),
        (e.id = "elite-iframe"),
        (e.referrerPolicy = "origin"),
        (e.frameBorder = 0),
        (e.seamless = "true"),
        document.body.append(e),
        document
          .getElementById("elite-iframe")
          .addEventListener("load", function () {
            let e = document.getElementById("elite-iframe");
            (e.style.position = "absolute"),
              (e.style.top = window.scrollY.toString() + "px"),
              (e.style.left = "0"),
              (e.style.width = "100%"),
              (e.style.height = "100vh");
            let t = new MessageChannel();
            (t.port1.onmessage = () => {
              document.body.style.maxHeight = maxHeight;
              document.body.style.overflow = overflow;
              document.getElementById("elite-iframe").remove();
            }),
              e.contentWindow.postMessage("init", "*", [t.port2]),
              t.port1.postMessage(
                JSON.stringify({
                  companyName: "Bentley",
                  primaryColor: "#0c71c3",
                  secondaryColor: "#FFFFFF",
                  logo: "https://imagedelivery.net/oeoLvNGjolwd3S4Vf5dZZg/41785651-dab9-4e27-46d3-180c80828f00/hd",
                  formType: "build",
                  demo: true,
                  buildData: {
                    image:
                      "https://images.ctfassets.net/d1etlz9zu1eq/52du7eXaZxqIWLe8jRj2XL/a106826673901f7bc996f859bb6dd46e/Legacy_QSB.png",
                    pricing: "50,923",
                    name: "Elite 223 Swingback Dual Capt.",
                  },
                })
              );
          });
    },
  },
};
</script>
<style>
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #eee;
}
.prequal-demo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 30px;
}
.prequal-demo h4 {
  color: #212121;
}
.prequal-demo__wrapper {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  width: 100%;
  max-width: 500px;
  padding: 30px 50px;
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
button {
  padding: 12px 24px;
  border-radius: 34px;
  border: none;
  background-color: #0174df;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
button:hover {
  cursor: pointer;
  background-color: #fff;
  color: #0174df;
}
</style>
